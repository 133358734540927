import styled from "styled-components";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

export const TarifsSectionStyled = styled.section`
  min-height: 102vh;
  width: 100%;
  padding: 5rem 0 5rem 0;
  background-color: ${({ theme }) => theme.colors.tarifsSectionBackground};
  scroll-padding-top: 80px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 5rem 0rem 5rem 0;
  }
`;

export const TarifsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
  

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
    
  }
`;

export const TarifsTableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 3rem;
  max-width:1400px;
  
  
`;

export const TarifsTableHeaderStyled = styled.th`
  background-color:${({theme}) => theme.colors.tarifsSectionBackgroundTableHeader};
  padding: 1rem;
  
  text-align: center;
  font-size:1.8rem;
  
`;

export const TarifsTableRowStyled = styled.tr`



  &:nth-child(even) {
    background-color: ${({theme}) => theme.colors.tarifsSectionBackgroundRowTwo};
    
  }
  &:nth-child(odd) {
  background-color: ${({theme}) => theme.colors.tarifsSectionBackgroundRowOne};
  
}

  

`;

export const TarifsTableDataStyled = styled.td`
  padding: 1rem;
  text-align: center;

 
  font-size: 1.6rem;
  font-weight:600;

`;

export const TarifsNoteStyled = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const TarifsTableWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  margin: 2rem 0;
  background-color:${({theme}) => theme.colors.tarifsSectionBackgroundTableHeader};
  border-radius: 10px;
  

  table {
    margin-bottom: 0;
    border: none;
    th,
    td {
      border: none;
      padding: 1rem;
    }
    th {
      font-weight: bold;
      color : ${({theme}) => theme.colors.tarifsSectionBackgroundTableHeaderText}
    }
    tbody {
      tr {
        border-bottom: 1px solid #ddd;
        &:last-child {
          border: none;
        }
        td {
          &:first-child {
            font-weight: bold;
            width: 20%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 40%;
            text-align: center;
          }
        }
      }
    }
  }
  p {
    margin: 1rem;
  }
`;


export const AccordionWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  
`;

export const AccordionHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: ${({theme}) => theme.colors.tarifsSectionAccordionContainer};
 
  cursor: pointer;
  border-radius:10px;
  margin-top:1rem;
  
`;

export const AccordionContentStyled = styled.div`

  padding: 10px;
  background-color:  ${({theme}) => theme.colors.tarifsSectionAccordionContent};
  border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
  font-size:16px;
 
 
width:96%;
margin: 0 auto;


  max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
  transition: max-height 2s ease-in-out !important;


`;

export const ServiceNameStyled = styled.span`
  font-weight: bold;
  font-size:16px;
  color : ${({theme}) => theme.colors.tarifsSectionAccordionText};
`;

export const PriceStyled = styled.span`
  margin-left: 10px;
  display:block;
  color : ${({theme}) => theme.colors.tarifsSectionAccordionText};
`;

export const FaAngleUpStyled = styled(FaAngleUp)`
  font-size: 24px;
  color: white;
`;

export const FaAngleDownStyled = styled(FaAngleDown)`
  font-size: 24px;
  color: white;
`;

export const TarifsImage = styled.div`

display: flex;
justify-content: center;

img {
  border: 1px solid black;
  max-width: 80%;
  height: auto;
  margin-bottom: -3px;
}
@media (max-width: ${({ theme }) => theme.mobile}) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 0px;
   }
   .fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  object-fit: contain;
  touch-action: auto;
}
  }
`;